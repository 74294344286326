<script setup>
import { useStore } from '@/store/index.js'
import { storeToRefs } from 'pinia'

const store = useStore()
const { tags } = storeToRefs(store)

defineProps({
  currentTag: String
})

</script>

<template>
  <div class="my-4 lg:my-12 font-family-sspro">
    <div class="flex flex-col lg:flex-row lg:items-center text-lg	font-semibold	bg-white">
      <NuxtLink :to="{path: '/blog/' }" class="px-2 lg:px-10 lg:py-6" :class="{'opacity-50' : currentTag}">
        Tutti
      </NuxtLink>
      <NuxtLink v-for="tag in tags.filter(t => t.attributes.articles.data.length)"
        :to="{ path: '/blog/tags/' + tag.attributes.slug + '/' }"
        class="px-2 lg:px-8 lg:py-6 hover:opacity-100 hover:text-mnet"
        :key="tag.attributes.slug"
        :class="{'opacity-50' : currentTag !== tag.attributes.slug}"
      >
        {{tag.attributes.name}}
      </NuxtLink>
    </div>
  </div>
</template>